import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ImCancelCircle } from "react-icons/im";
import './image-preview.scss';

const ImagePreview = ({ dataUri, isFullscreen, Images }) => {

    let classNameFullscreen = isFullscreen ? 'demo-image-preview-fullscreen' : '';




    const basicUrls = Images.map(item => item.Image.basicUrl.split("/").pop().slice(0, item.Image.basicUrl.split("/").pop().indexOf(".png")));


    const [droppedImages, setDroppedImages] = useState([]);

    const [positioning, setPositioning] = useState([]);

    const [newImage, setNewImage] = useState(null)


    const [initialY, setInitialY] = useState(null);

    const handleDragStart = (e, img) => {

        const y = e.clientY;
        setInitialY(y);
        setNewImage(img)

    };


    const handleDrop = (e, chosenEmoji, displayedEmoji) => {

        const box = document.querySelector(".border-drop");

        const offsetX = e.clientX - box.getBoundingClientRect().left - 25 || 0;
        const offsetY = e.clientY - box.getBoundingClientRect().top - 25 || 0;

        if (basicUrls.includes(chosenEmoji)) {

            if (!droppedImages.includes(displayedEmoji)) {

                setDroppedImages([...droppedImages, displayedEmoji]);
                setPositioning({ ...positioning, [displayedEmoji]: { offsetX, offsetY } });
            } else {

                if (initialY < box.getBoundingClientRect().bottom) {

                    setPositioning({ ...positioning, [newImage]: { offsetX, offsetY } });
                }
                else {

                    const duplicate = `${displayedEmoji}-${Date.now()}`
                    setDroppedImages([...droppedImages, duplicate]);
                    setPositioning({ ...positioning, [duplicate]: { offsetX, offsetY } });
                }
            }
        }

    };


    const deleteEmoji = (displayedEmoji) => {

        const newArray = droppedImages.filter(
            (img) => img !== displayedEmoji
        );

        const { [displayedEmoji]: deletedImage, ...resetPositioning } =
            positioning;

        setDroppedImages(newArray);
        setPositioning(resetPositioning);

    };

    const clear = () => {
        setDroppedImages([])
        setPositioning([]);
    }


    return (
        <div className={'demo-image-preview ' + classNameFullscreen}>
            <div className="emoji-section">
                <img
                    src={dataUri}

                    className="border-drop"
                    draggable={true}

                    style={{
                        width: "100%",
                        //height: "300px",
                        //border: "2px dashed #000",
                        position: "relative",
                        maxWidth: "600px",
                        margin: "0 auto",
                    }}
                    onDragOver={(e) => {
                        e.preventDefault();

                    }}
                    onDrop={(e) => {
                        e.preventDefault();
                        const displayedImage = `${e.dataTransfer.getData("text/uri-list")}`

                        const image = `${e.dataTransfer.getData("text/uri-list")}`.split("/");
                        const staticImage = image.pop()
                        const newImage = staticImage.slice(0, staticImage.indexOf(".webp"))

                        handleDrop(e, newImage, displayedImage);
                    }}

                />



                {droppedImages.map((image, index) => (
                    <div className="overlay">
                        <div className="overlay-content" key={index} style={{ position: "absolute", left: `${positioning[image]?.offsetX || 0}px`, top: `${positioning[image]?.offsetY || 0}px` }}>
                            <button className="close-btn" onClick={() => deleteEmoji(image)}><ImCancelCircle /></button>
                            <img src={image.split("-") ? image.split("-")[0] : image}
                                width="50"
                                className="dnd-img"
                                onDragStart={(e) => handleDragStart(e, image)}
                            />
                        </div>
                    </div>
                ))}

                <div className="images">
                    <div className="uk-position-relative uk-visible-toggle uk-light" tabIndex="-1">
                        <ul className="uk-grid display-emojis">
                            {
                                Images.map((item, index) => {

                                    return (
                                        <li key={index}>
                                            <div
                                                className="uk-panel image-emoji"
                                                onDragStart={(e) => handleDragStart(e)}
                                                uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 500"
                                            >
                                                <GatsbyImage image={item.Image?.gatsbyImageData} alt="" width="20px" />
                                            </div>
                                        </li>
                                    )
                                })
                            }


                        </ul>
                    </div>
                    {/* <button className="restart" onClick={() => clear()}>click <b>here</b> and restart customizing</button> */}
                </div>

            </div>
        </div>
    );
};

ImagePreview.propTypes = {
    dataUri: PropTypes.string,
    isFullscreen: PropTypes.bool
};

export default ImagePreview;