import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import "../styles/your-review.scss"
import Paragraph from "../components/paragraph/paragraph"
import { SlLike } from "react-icons/sl";
import { SlDislike } from "react-icons/sl";
import { MdDoneOutline } from "react-icons/md";
import Camera, { FACING_MODES } from 'react-html5-camera-photo';

import 'react-html5-camera-photo/build/css/index.css';
import ImagePreview from "../components/image-preview/image-preview";
import { FiCameraOff } from "react-icons/fi";
import { FiCamera } from "react-icons/fi";
import no_photo from '../assets/no-photo.png'
import GetImgUrl from "../utils/customFunctions";
// import ImageComponent from "../components/image-component/image-component";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
// import ReviewDisplay from "../components/review-display/review-display";
import SecondaryLayout from "../layouts/secondary-layout";


// markup
const ReviewPage = ({ pageContext }) => {

    const data = useStaticQuery(graphql`
     {
          strapiReview {
            PageComponents
            Seo {
                metaDescription
                metaTitle
              }
          }
    }
    `
    )

    const [error, setError] = useState(null)

    const [currentStep, setCurrentStep] = useState(1);

    const [experience, setExperience] = useState(typeof window !== "undefined" && sessionStorage.getItem("experience") ?
        sessionStorage.getItem("experience") : ""
    );

    const [message, setMessage] = useState(typeof window !== "undefined" && sessionStorage.getItem("message") ?
        sessionStorage.getItem("message") : ""
    );


    useEffect(() => {
        if (typeof window !== 'undefined') { sessionStorage.setItem("experience", experience) };
    }, [experience]

    );

    useEffect(() => {
        if (typeof window !== 'undefined') { sessionStorage.setItem("message", message) };
    }, [message]);


    const handleBack = () => {
        setError(null)
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
            // if (currentStep === 3) {
            //     sessionStorage.removeItem("message");
            //     setMessage("");
            // }
            if (currentStep === 2) {
                sessionStorage.removeItem("experience");
                setExperience("");
            }
        }
    };

    useEffect(() => {
        if (experience === "good" || experience === "bad") {
            handleNext();
        }
    }, [experience]);


    const handleNext = () => {
        setError(null)
        if (currentStep === 1 && experience !== "") {
            setCurrentStep(2);
        } else if (currentStep === 2 && message !== "") {
            setCurrentStep(3);
        } else if (currentStep === 3 && dataUri === '') {
            setError("Please submit with your photo.")
        } else if (currentStep === 3 && dataUri !== '') {
            setCurrentStep(4)
        } else {
            setError("All fields must be completed!")
        }
    };

    const handleStartAgain = () => {
        setError(null)
        setCurrentStep(1);
        setExperience("");
        setMessage("");
        setDataUri("");
        setCameraStarted(false);
        sessionStorage.removeItem("experience");
        sessionStorage.removeItem("message");
    };


    const [dataUri, setDataUri] = useState('');
    const [cameraStarted, setCameraStarted] = useState(false);

    function handleTakePhotoAnimationDone(dataUri) {
        //console.log('takePhoto');
        setDataUri(dataUri);
    }

    const isFullscreen = false;

    // function handleCameraStart(stream) {
    //     setCameraStarted(true)
    //     console.log('handleCameraStart');
    // }

    // function handleCameraStop() {
    //     //setCameraStarted(false)
    //     console.log('handleCameraStop');
    // }

    const handleStartCamera = () => {
        setCameraStarted(true);
    };
    const handleStopCamera = () => {
        setCameraStarted(false);
    };

    //test on mobile
    const isTouchDevice = () => {
        if ((typeof window !== 'undefined') && ("ontouchstart" in window)) {
            return true;
        }
        return false;
    };

    const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;


    return (
        <SecondaryLayout SEOTitle={data.strapiReview.Seo.metaTitle} SEODescription={data.strapiReview.Seo.metaDescription}>

            <div className="max-devices"
                style={{ minHeight: "800px", display: "grid", alignItems: "center" }}
            >
                <Paragraph
                    Title="Sorry, we can't find that page"
                    Text="The content unfolds exclusively for mobile devices, due to the camera-review process."
                    Center={true}
                    TopPadding={true}
                    BottomPadding={true} />
            </div>


            <div className="mobile-devices" style={{ display: "grid", alignItems: "center", padding: "10rem 0rem" }}>
                <div className="review-container">
                    {/* {console.log(currentStep, message, experience, "step")} */}
                    {currentStep === 1 && (
                        <div className="uk-animation-slide-right-medium">
                            <h2 className="title-step1">How was your experience?</h2>
                            <button onClick={() => setExperience("good")} className="good-rating"><SlLike /></button>
                            <button onClick={() => setExperience("bad")} className="bad-rating"><SlDislike /></button>
                        </div>
                    )}
                    {currentStep === 2 && (
                        <div className="uk-animation-slide-right-medium">
                            <h2 style={{ marginBottom: "3rem" }}>Leave us a message</h2>
                            <textarea
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            ></textarea>
                        </div>
                    )}
                    {currentStep === 3 && (
                        <div className="uk-animation-slide-right-medium">
                            <h2 className="title-step3">Open camera and customize your review</h2>
                            {
                                (dataUri) ?

                                    data.strapiReview.PageComponents.map((item, index) => {
                                        switch (item.strapi_component) {

                                            case "page-components.image-component":
                                                let imgCards = [];
                                                item.review_emojis.map((item, index) => {
                                                    let Image = GetImgUrl(item.Image.localFile___NODE, pageContext.images);
                                                    imgCards.push({
                                                        Image: Image,
                                                    })
                                                })


                                                return (
                                                    <>
                                                        <DndProvider backend={backendForDND} key={index}>
                                                            <ImagePreview
                                                                dataUri={dataUri}
                                                                isFullscreen={isFullscreen}
                                                                Images={imgCards}
                                                                draggable={true}
                                                            />
                                                        </DndProvider>

                                                        <button className="restart" onClick={() => { setCameraStarted(true); setDataUri('') }}>click <b>here</b> and restart customizing</button>

                                                    </>
                                                )

                                            default:
                                                return ("")
                                        }
                                    })
                                    :
                                    (
                                        <div style={{ display: "inline-grid" }}>
                                            {
                                                cameraStarted ?

                                                    <Camera
                                                        onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
                                                        isImageMirror={true}
                                                        isFullscreen={isFullscreen}
                                                        idealFacingMode={cameraStarted ? FACING_MODES.USER : "environment"}
                                                    />

                                                    :

                                                    <img src={no_photo} alt="No photo" width="540" className="no-photo" />

                                            }

                                            {cameraStarted ? (
                                                <button onClick={handleStopCamera} className="camera-btn"><FiCameraOff /></button>
                                            ) : (
                                                <button onClick={handleStartCamera} className="camera-btn"><FiCamera /></button>
                                            )}
                                        </div>
                                    )
                            }
                        </div>
                    )}

                    {/* {currentStep === 4 && (
                        <div className="uk-animation-slide-right-medium">
                            <ReviewDisplay selfie={dataUri} />
                        </div>
                    )} */}

                    {currentStep === 4 && (
                        <div className="uk-animation-slide-right-medium laststep">
                            <p className="checkmark-done"><MdDoneOutline /></p>
                            <p style={{ fontSize: "18px", padding: "0px 16px" }}>Thank you! Your review has been submitted.</p>

                            <a href="/" className="btn-home">Home</a>
                        </div>
                    )}

                    {currentStep !== 4 && (<div className="buttons">
                        <button onClick={handleBack} className={currentStep === 1 ? "display-none" : "btn-back"}>Back</button>
                        <button onClick={handleStartAgain} className={currentStep === 1 ? "display-none" : "btn-start"}>Start</button>
                        <button onClick={handleNext} className={currentStep === 1 ? "display-none" : "btn-next"}>{currentStep === 3 ? "Submit" : "Next"}</button>
                    </div>
                    )}

                    {error && currentStep !== 4 ? <p style={{ color: "red" }}>{error}</p> : ""}

                </div>
            </div>

        </SecondaryLayout>
    )
}

export default ReviewPage
