import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import CustomMarkdown from "../../utils/customMarkdown"
import "./paragraph.scss"

const Paragraph = ({ Title, Text, TopPadding, BottomPadding, Center }) => {


    return (
        <>
            <div className={`side-image-container ${TopPadding ? ' padding-top ' : ''} ${BottomPadding ? ' padding-bottom ' : ''}`}>
                <div className="side-image-section">

                    <div className="alignment">
                        {Title ? <h1 className="title">{Title}</h1> : ""}
                        <b></b>
                    </div>

                    <div className="uk-child-width-expand@s uk-grid-small uk-margin-remove " data-uk-grid>

                        <div className={"uk-width-1-1@m"}
                        //id="padding-right"
                        >
                            {
                                Center ?
                                    <div className="side-image-section__description" style={{ textAlign: "center" }}>
                                        <CustomMarkdown children={Text}
                                        />
                                    </div>

                                    : <div className="side-image-section__description"><CustomMarkdown children={Text} /></div>
                            }
                        </div>

                    </div>

                </div>
            </div>
        </>
    )




}

export default Paragraph